var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Rekap Slip Gaji"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bulan",
      "label-for": "v-Bulan"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optbulan
    },
    model: {
      value: _vm.bulan,
      callback: function ($$v) {
        _vm.bulan = $$v;
      },
      expression: "bulan"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tahun",
      "label-for": "v-Tahun"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-tahun",
      "placeholder": "tahun",
      "type": "number"
    },
    model: {
      value: _vm.tahun,
      callback: function ($$v) {
        _vm.tahun = $$v;
      },
      expression: "tahun"
    }
  })], 1)], 1), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.right",
      value: 'Print / Cetak',
      expression: "'Print / Cetak'",
      modifiers: {
        "hover": true,
        "right": true
      }
    }],
    staticClass: "mr-50",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.unduhrekap($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Print Rekap Gaji ")], 1), _c('b-alert', {
    staticClass: "mt-1",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Keterangan ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Pilih Bulan dan Tahun untuk print \"Slip Gaji\" per karyawan dan \"Rekap Gaji\" seluruh karyawan")])])])], 1)], 1)], 1)], 1), _c('b-col', [_c('b-card-actions', {
    ref: "refreshCard",
    attrs: {
      "action-collapse": "",
      "title": "Daftar Penggajian"
    }
  }, [_c('b-row', [_vm.allowCreate() ? _c('b-col', {
    staticClass: "my-1"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addWizard($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Penggajian ")], 1)], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "size": "xl",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form"
    },
    model: {
      value: _vm.showModalWizard,
      callback: function ($$v) {
        _vm.showModalWizard = $$v;
      },
      expression: "showModalWizard"
    }
  }, [_c('form-wizard', {
    staticClass: "wizard-vertical mb-3",
    attrs: {
      "color": "#ff9801",
      "title": null,
      "subtitle": null,
      "finish-button-text": "Simpan",
      "back-button-text": "Sebelumnya",
      "next-button-text": "Selanjutnya"
    },
    on: {
      "on-complete": _vm.submit
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Pokok",
      "before-change": _vm.validationForm
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Karyawan",
      "label-for": "v-karyawan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "karyawan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optkaryawan,
            "label": "Karyawan"
          },
          model: {
            value: _vm.form.id_karyawan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_karyawan", $$v);
            },
            expression: "form.id_karyawan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gaji Pokok",
      "label-for": "v-pokok"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pokok",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-pokok",
            "placeholder": "pokok"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.pokok = _vm.formatRupiah(_vm.form.pokok);
            }
          },
          model: {
            value: _vm.form.pokok,
            callback: function ($$v) {
              _vm.$set(_vm.form, "pokok", $$v);
            },
            expression: "form.pokok"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Makan",
      "label-for": "v-makan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "makan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-makan",
            "placeholder": "makan"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.makan = _vm.formatRupiah(_vm.form.makan);
            }
          },
          model: {
            value: _vm.form.makan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "makan", $$v);
            },
            expression: "form.makan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Transportasi",
      "label-for": "v-transportasi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "transportasi",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-transportasi",
            "placeholder": "transportasi"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.transportasi = _vm.formatRupiah(_vm.form.transportasi);
            }
          },
          model: {
            value: _vm.form.transportasi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "transportasi", $$v);
            },
            expression: "form.transportasi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tunjangan Jabatan",
      "label-for": "v-tun_jabatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tun_jabatan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tun_jabatan",
            "placeholder": "tun_jabatan"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.tun_jabatan = _vm.formatRupiah(_vm.form.tun_jabatan);
            }
          },
          model: {
            value: _vm.form.tun_jabatan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tun_jabatan", $$v);
            },
            expression: "form.tun_jabatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tunjangan Kinerja",
      "label-for": "v-tun_kinerja"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tun_kinerja",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tun_kinerja",
            "placeholder": "tun_kinerja"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.tun_kinerja = _vm.formatRupiah(_vm.form.tun_kinerja);
            }
          },
          model: {
            value: _vm.form.tun_kinerja,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tun_kinerja", $$v);
            },
            expression: "form.tun_kinerja"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lama Kerja (bulan)",
      "label-for": "v-lama_kerja"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lama_kerja",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-lama_kerja",
            "placeholder": "lama_kerja",
            "type": "number"
          },
          model: {
            value: _vm.form.lama_kerja,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lama_kerja", $$v);
            },
            expression: "form.lama_kerja"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bonus",
      "label-for": "v-bonus"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bonus",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-bonus",
            "placeholder": "bonus"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.bonus = _vm.formatRupiah(_vm.form.bonus);
            }
          },
          model: {
            value: _vm.form.bonus,
            callback: function ($$v) {
              _vm.$set(_vm.form, "bonus", $$v);
            },
            expression: "form.bonus"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Lembur"
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lembur",
      "label-for": "v-lembur"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lembur",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-lembur",
            "placeholder": "lembur"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.lembur = _vm.formatRupiah(_vm.form.lembur);
            }
          },
          model: {
            value: _vm.form.lembur,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lembur", $$v);
            },
            expression: "form.lembur"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lembur di hari libur",
      "label-for": "v-lembur_libur"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lembur_libur",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-lembur_libur",
            "placeholder": "lembur_libur"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.lembur_libur = _vm.formatRupiah(_vm.form.lembur_libur);
            }
          },
          model: {
            value: _vm.form.lembur_libur,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lembur_libur", $$v);
            },
            expression: "form.lembur_libur"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tunjangan Hari Raya",
      "label-for": "v-tun_hari_raya"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tun_hari_raya",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tun_hari_raya",
            "placeholder": "tun_hari_raya"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.tun_hari_raya = _vm.formatRupiah(_vm.form.tun_hari_raya);
            }
          },
          model: {
            value: _vm.form.tun_hari_raya,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tun_hari_raya", $$v);
            },
            expression: "form.tun_hari_raya"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Potongan"
    }
  }, [_c('validation-observer', {
    ref: "lastForm"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "BPJS Kesehatan",
      "label-for": "v-bpjs_kesehatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bpjs_kesehatan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-bpjs_kesehatan",
            "placeholder": "bpjs_kesehatan"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.bpjs_kesehatan = _vm.formatRupiah(_vm.form.bpjs_kesehatan);
            }
          },
          model: {
            value: _vm.form.bpjs_kesehatan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "bpjs_kesehatan", $$v);
            },
            expression: "form.bpjs_kesehatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "BPJS Ketenagakerjaan",
      "label-for": "v-bpjs_ketenagakerjaan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bpjs_ketenagakerjaan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-bpjs_ketenagakerjaan",
            "placeholder": "bpjs_ketenagakerjaan"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.bpjs_ketenagakerjaan = _vm.formatRupiah(_vm.form.bpjs_ketenagakerjaan);
            }
          },
          model: {
            value: _vm.form.bpjs_ketenagakerjaan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "bpjs_ketenagakerjaan", $$v);
            },
            expression: "form.bpjs_ketenagakerjaan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telat",
      "label-for": "v-telat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "telat",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-telat",
            "placeholder": "telat"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.telat = _vm.formatRupiah(_vm.form.telat);
            }
          },
          model: {
            value: _vm.form.telat,
            callback: function ($$v) {
              _vm.$set(_vm.form, "telat", $$v);
            },
            expression: "form.telat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cicilan",
      "label-for": "v-cicilan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "cicilan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-cicilan",
            "placeholder": "cicilan"
          },
          on: {
            "keyup": function ($event) {
              _vm.form.cicilan = _vm.formatRupiah(_vm.form.cicilan);
            }
          },
          model: {
            value: _vm.form.cicilan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "cicilan", $$v);
            },
            expression: "form.cicilan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-s",
      "size": "xl",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form"
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formKaryawan"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("Data Penggajian")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Karyawan",
      "label-for": "v-karyawan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "karyawan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optkaryawan,
            "label": "Karyawan"
          },
          model: {
            value: _vm.form.id_karyawan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_karyawan", $$v);
            },
            expression: "form.id_karyawan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gaji Pokok",
      "label-for": "v-pokok"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pokok",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-pokok",
            "placeholder": "pokok",
            "type": "number"
          },
          model: {
            value: _vm.form.pokok,
            callback: function ($$v) {
              _vm.$set(_vm.form, "pokok", $$v);
            },
            expression: "form.pokok"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tunjangan Jabatan",
      "label-for": "v-tun_jabatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tun_jabatan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        var errors = _ref18.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tun_jabatan",
            "placeholder": "tun_jabatan",
            "type": "number"
          },
          model: {
            value: _vm.form.tun_jabatan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tun_jabatan", $$v);
            },
            expression: "form.tun_jabatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tunjangan Kinerja",
      "label-for": "v-tun_kinerja"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tun_kinerja",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tun_kinerja",
            "placeholder": "tun_kinerja",
            "type": "number"
          },
          model: {
            value: _vm.form.tun_kinerja,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tun_kinerja", $$v);
            },
            expression: "form.tun_kinerja"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lama Kerja",
      "label-for": "v-lama_kerja"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lama_kerja",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref20) {
        var errors = _ref20.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-lama_kerja",
            "placeholder": "lama_kerja",
            "type": "number"
          },
          model: {
            value: _vm.form.lama_kerja,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lama_kerja", $$v);
            },
            expression: "form.lama_kerja"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Makan",
      "label-for": "v-makan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "makan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref21) {
        var errors = _ref21.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-makan",
            "placeholder": "makan",
            "type": "number"
          },
          model: {
            value: _vm.form.makan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "makan", $$v);
            },
            expression: "form.makan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("-")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lembur",
      "label-for": "v-lembur"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lembur",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref22) {
        var errors = _ref22.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-lembur",
            "placeholder": "lembur",
            "type": "number"
          },
          model: {
            value: _vm.form.lembur,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lembur", $$v);
            },
            expression: "form.lembur"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lembur di hari libur",
      "label-for": "v-lembur_libur"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lembur_libur",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref23) {
        var errors = _ref23.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-lembur_libur",
            "placeholder": "lembur_libur",
            "type": "number"
          },
          model: {
            value: _vm.form.lembur_libur,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lembur_libur", $$v);
            },
            expression: "form.lembur_libur"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tunjangan Hari Raya",
      "label-for": "v-tun_hari_raya"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tun_hari_raya",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref24) {
        var errors = _ref24.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tun_hari_raya",
            "placeholder": "tun_hari_raya",
            "type": "number"
          },
          model: {
            value: _vm.form.tun_hari_raya,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tun_hari_raya", $$v);
            },
            expression: "form.tun_hari_raya"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "BPJS Kesehatan",
      "label-for": "v-bpjs_kesehatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bpjs_kesehatan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref25) {
        var errors = _ref25.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-bpjs_kesehatan",
            "placeholder": "bpjs_kesehatan",
            "type": "number"
          },
          model: {
            value: _vm.form.bpjs_kesehatan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "bpjs_kesehatan", $$v);
            },
            expression: "form.bpjs_kesehatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "BPJS Ketenagakerjaan",
      "label-for": "v-bpjs_ketenagakerjaan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bpjs_ketenagakerjaan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref26) {
        var errors = _ref26.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-bpjs_ketenagakerjaan",
            "placeholder": "bpjs_ketenagakerjaan",
            "type": "number"
          },
          model: {
            value: _vm.form.bpjs_ketenagakerjaan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "bpjs_ketenagakerjaan", $$v);
            },
            expression: "form.bpjs_ketenagakerjaan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telat",
      "label-for": "v-telat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "telat",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref27) {
        var errors = _ref27.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-telat",
            "placeholder": "telat",
            "type": "number"
          },
          model: {
            value: _vm.form.telat,
            callback: function ($$v) {
              _vm.$set(_vm.form, "telat", $$v);
            },
            expression: "form.telat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cicilan",
      "label-for": "v-cicilan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "cicilan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref28) {
        var errors = _ref28.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-cicilan",
            "placeholder": "cicilan",
            "type": "number"
          },
          model: {
            value: _vm.form.cicilan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "cicilan", $$v);
            },
            expression: "form.cicilan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label || "Submit") + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(id)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(jk)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.jk[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.jk[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(id_karyawan)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "dark"
          }
        }, [_vm._v(" " + _vm._s(_vm.getJabatanName(data.item)) + " ")])];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref29) {
        var item = _ref29.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.total > 0 ? _vm.formatRupiah(item.total) : item.total))])];
      }
    }, {
      key: "cell(lama_kerja)",
      fn: function (_ref30) {
        var item = _ref30.item;
        return [_c('strong', [_vm._v("Rp " + _vm._s(item.lama_kerja > 0 ? _vm.formatRupiah(item.lama_kerja) : item.lama_kerja))])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Print / Cetak',
            expression: "'Print / Cetak'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.unduhbop(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PrinterIcon"
          }
        })], 1), _vm.allowUpdate() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.detailmodal.id,
      "title": _vm.detailmodal.title,
      "ok-only": ""
    },
    on: {
      "hide": true
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.detailmodal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }